import {Dispatch} from "../constants/globalTypes";
// import {getGroupProperties, setActiveStair} from "../components/group/GroupActions";
import {authFetch} from "../helpers/createAuthProvider"
//
export const ADD_GROUP_TO_BREADCRUMBS = "add_group_to_breadcrumbs";
// export const addGroupToBreadCrumbs = ({
//                                           dispatch,
//                                           groupInfo
//                                       }
//                                           :
//                                           {
//                                               dispatch: Dispatch,
//                                               groupInfo: { name: string, id: string, customOnClick?: boolean, crumbType?: string }
//                                           }
// ) => {
//
//     dispatch({
//         type: ADD_GROUP_TO_BREADCRUMBS,
//         payload: groupInfo
//     })
// };
//
export const GET_GROUPS_BREADCRUMBS_FROM_LOCAL_STORAGE = 'get_groups_breadcrumbs_from_localstorage';
// export const getGroupsBreadcrumbsFromLocalStorage = ({
//                                                          dispatch
//                                                      }: {
//                                                          dispatch: Dispatch
//                                                      }
// ) => {
//     dispatch({
//         type: GET_GROUPS_BREADCRUMBS_FROM_LOCAL_STORAGE,
//         payload: {}
//     })
// };
export const RESET_BREAD_CRUMBS_ARRAY = 'reset_bread_crumbs_array';
// export const resetBreadCrumbsArray = ({
//                                           dispatch
//                                       }: {
//                                           dispatch: Dispatch
//                                       }
// ) => {
//     dispatch({
//         type: RESET_BREAD_CRUMBS_ARRAY,
//         payload: {}
//     })
// };
//
// export const setActiveStairGlobal = ({
//                                          dispatch,
//                                          stairId,
//                                          associationId
//
//                                      }
//                                          :
//                                          {
//                                              dispatch: Dispatch,
//                                              stairId: string,
//                                              associationId: string
//                                          }
// ) => {
//     setActiveStair({dispatch, id: stairId})
//     getGroupProperties({dispatch, associationId, groupId: stairId})
// };
//
export const CHANGE_SELECTED_OPTION = "change_selected_option";
export const changeSelectedOption = ({
                                         dispatch,
                                         option
                                     }: {
                                         dispatch: Dispatch,
                                         option: string
                                     }
) => {
    dispatch({
        type: CHANGE_SELECTED_OPTION,
        payload: option
    })
};
export const SEARCH_TERM = "search_term";
export const SEARCH_TERM_SUCCESS = "search_term_success";
export const SEARCH_TERM_FAIL = "search_term_fail";
//
export const onSearchTerm = ({
                                 dispatch,
                                 searchString,
                                 selectedOption
                             }:
                                 {
                                     dispatch: Dispatch,
                                     searchString: string,
                                     selectedOption: string,
                                 }
) => {
    dispatch({
        type: SEARCH_TERM,
        payload: {}
    });
    authFetch(`/associations/search?searchBy=${selectedOption}&searchFor=${searchString}`, {
        method: "GET"
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: SEARCH_TERM_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: SEARCH_TERM_FAIL,
                payload: error
            })
        })
};