import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from '../../helpers/createAuthProvider';

export const GET_USER = 'get_user';
export const GET_USER_SUCCESS = 'get_user_success';
export const GET_USER_FAIL = 'get_user_fail';

const getLoggedUser = (
    {dispatch}: { dispatch: Dispatch }) => {

    dispatch({type: GET_USER, payload: {}});
    authFetch(`/users/email/${localStorage.getItem("email")}`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            localStorage.setItem("userId", data.id);
            dispatch({
                type: GET_USER_SUCCESS,
                payload: data
            })
        })
        .catch((error) => {
            dispatch({
                type: GET_USER_FAIL,
                payload: error
            })
        });
};
export const UPDATE_USER = "update_user";
export const UPDATE_USER_SUCCESS = "update_user_success"
export const UPDATE_USER_FAIL = "update_user_fail"

const updateUser = ({dispatch, values, history}: { dispatch: Dispatch, values: any, history: any }) => {
    let responseStats: number;

    dispatch({type: UPDATE_USER, payload: {}});

    authFetch(`/users/updateUser`, {
        method: "POST",
        body: JSON.stringify(values)
    }).then((response) => {
        responseStats = response.status
        return response.json()
    }).then((data) => {
        if (responseStats == 200) {
            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: data,
            });
            getLoggedUser({dispatch})

            history.goBack();
        } else {
            dispatch({
                type: UPDATE_USER_FAIL,
                payload: data.message,
            });
        }
    }).catch((error) => {
        dispatch({
            type: UPDATE_USER_FAIL,
            payload: error,
        });
    });
}


export {getLoggedUser, updateUser}
