import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from '../../helpers/createAuthProvider';

export const GET_PROPERTIES = "get_properties";
export const GET_PROPERTIES_SUCCESS = "get_properties_success";
export const GET_PROPERTIES_FAIL = "get_properties_fail";

const getOwnedProperties = (
    {dispatch}: { dispatch: Dispatch }) => {
    dispatch({type: GET_PROPERTIES, payload: {}});
    authFetch(`/properties//owned`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            console.log(data)
            dispatch({
                type: GET_PROPERTIES_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_PROPERTIES_FAIL,
                payload: error
            })
        });
};

export const GET_PROPERTY = 'get_property';
export const GET_PROPERTY_SUCCESS = 'get_property_success';
export const GET_PROPERTY_FAIL = 'get_property_fail';
export const getPropertyById = ({
                                    dispatch,
                                    propertyId,
                                    associationId
                                }: {
                                    dispatch: Dispatch,
                                    propertyId: string,
                                    associationId: string
                                }
) => {
    dispatch({
        type: GET_PROPERTY,
        payload: {}
    });
    authFetch(`/properties/${associationId}/property/${propertyId}`, {
        method: "GET"
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            }
            return response.text().then(error => {
                dispatch({
                    type: GET_PROPERTY_FAIL,
                    payload: error
                })
            })
        })
        .then(data => {
            dispatch({
                type: GET_PROPERTY_SUCCESS,
                payload: data
            })
        })
        .catch(error => console.log("getPropertyById", error))
};

export const GET_PROPERTY_COSTS = 'get_property_costs';
export const GET_PROPERTY_COSTS_SUCCESS = 'get_property_costs_success';
export const GET_PROPERTY_COSTS_FAIL = 'get_property_costs_fail';
export const getPropertyCosts = ({
                                     dispatch,
                                     propertyId,
                                     associationId,
                                     selectedYear
                                 }:
                                     {
                                         dispatch: Dispatch,
                                         propertyId: string,
                                         associationId: string,
                                         selectedYear: string
                                     }
) => {
    dispatch({
        type: GET_PROPERTY_COSTS,
        payload: {}
    });
    authFetch(`/costs/${associationId}/distributions/${propertyId}?year=${selectedYear}`, {
        method: 'GET'
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            }
            return response.text().then(error => {
                dispatch({
                    type: GET_PROPERTY_COSTS_FAIL,
                    payload: error
                })
            })
        })
        .then(data => {
            dispatch({
                type: GET_PROPERTY_COSTS_SUCCESS,
                payload: data
            })
        })
        .catch(error => console.log("getPropertyCosts", error))
};

export const GET_PROPERTY_YEARS_WITH_DATA = 'get_property_years_with_data';
export const GET_PROPERTY_YEARS_WITH_DATA_SUCCESS = 'get_property_years_with_data_success';
export const GET_PROPERTY_YEARS_WITH_DATA_FAIL = 'get_property_years_with_data_fail';
export const getPropertyYearsWithData = ({
                                             dispatch,
                                             associationId,
                                             propertyId
                                         }:
                                             {
                                                 dispatch: Dispatch,
                                                 associationId: string,
                                                 propertyId: string
                                             }
) => {
    dispatch({
        type: GET_PROPERTY_YEARS_WITH_DATA,
        payload: {}
    });
    authFetch(`/costs/${associationId}/distributions/${propertyId}/registered-years`, {
        method: "GET"
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            }
            return response.text().then(error =>
                dispatch({
                    type: GET_PROPERTY_YEARS_WITH_DATA_FAIL,
                    payload: error
                }))
        })
        .then(data => {
            dispatch({
                type: GET_PROPERTY_YEARS_WITH_DATA_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            console.log("Get registered years", error)
        })
};

export const CHANGE_SELECTED_YEAR = 'change_selected_year';
export const changeSelectedYear = ({
                                       dispatch,
                                       year
                                   }:
                                       {
                                           dispatch: Dispatch,
                                           year: string
                                       }
) => {
    dispatch({
        type: CHANGE_SELECTED_YEAR,
        payload: year
    })
};

export const GET_COSTS_DETAILS_BY_MONTH = 'get_costs_details_by_month';
export const GET_COSTS_DETAILS_BY_MONTH_SUCCESS = 'get_costs_details_by_month_success';
export const GET_COSTS_DETAILS_BY_MONTH_FAIL = 'get_costs_details_by_month_fail';
export const getCostsDetailsByMonth = ({
                                           dispatch,
                                           associationId,
                                           propertyId,
                                           year,
                                           month
                                       }:
                                           {
                                               dispatch: Dispatch,
                                               associationId: string,
                                               propertyId: string,
                                               year: string,
                                               month: string
                                           }
) => {
    dispatch({
        type: GET_COSTS_DETAILS_BY_MONTH,
        payload: {}
    });
    authFetch(`/costs/${associationId}/distributions/${propertyId}?year=${year}&month=${month}`, {
        method: "GET"
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.text().then(error => {
                    dispatch({
                        type: GET_COSTS_DETAILS_BY_MONTH_FAIL,
                        payload: error
                    })
                })
            }
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_COSTS_DETAILS_BY_MONTH_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("ERRE", error)
        })
};

export const CHANGE_SELECTED_MONTH = 'change_selected_month';
export const changeSelectedMonth = ({dispatch, month}: { dispatch: Dispatch, month: string }) => {
    dispatch({
        type: CHANGE_SELECTED_MONTH,
        payload: month
    })
};

export {
    getOwnedProperties,
}