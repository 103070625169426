
import {History} from "history";
import { authFetch } from "../../helpers/createAuthProvider";
import {Dispatch} from "../../constants/globalTypes";

export const GET_PAYMENTS = 'get_payments';
export const GET_PAYMENTS_SUCCESS = 'get_payments_success';
export const GET_PAYMENTS_FAIL = 'get_payments_fail';
export const ADD_PAYMENT = 'add_payment';
export const ADD_PAYMENT_SUCCESS = 'add_payment_success';
export const ADD_PAYMENT_FAIL = 'add_payment_fail';
export const GET_PAYMENT = 'get_payment';
export const GET_PAYMENT_SUCCESS = 'get_payment_success';
export const GET_PAYMENT_FAIL = 'get_payment_fail';
export const UPDATE_PAYMENT = 'update_payment';
export const UPDATE_PAYMENT_SUCCESS = 'update_payment_success';
export const UPDATE_PAYMENT_FAIL = 'update_payment_fail';
export const DELETE_PAYMENT = 'delete_payment';
export const DELETE_PAYMENT_SUCCESS = 'delete_payment_success';
export const DELETE_PAYMENT_FAIL = 'delete_payment_fail';
export const GET_PROPERTY_PAYMENTS = 'get_property_payments';
export const GET_PROPERTY_PAYMENTS_SUCCESS = 'get_property_payments_success';
export const GET_PROPERTY_PAYMENTS_FAIL = 'get_property_payments_fail';
export const GET_GROUP_PAYMENTS = 'get_group_payments';
export const GET_GROUP_PAYMENTS_SUCCESS = 'get_group_payments_success';
export const GET_GROUP_PAYMENTS_FAIL = 'get_group_payments_fail';

export const getAllPayments = ({dispatch}: { dispatch: Dispatch }, associationId: string) => {
    dispatch({type: GET_PAYMENTS, payload: {}});
    authFetch(`/costs/${associationId}/payments`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_PAYMENTS_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_PAYMENTS_FAIL,
                payload: error
            })
        });
};

export const getPropertyPayments = ({dispatch}: { dispatch: Dispatch }, associationId: string, propertyId: string) => {
    dispatch({type: GET_PROPERTY_PAYMENTS, payload: {}});
    authFetch(`/costs/${associationId}/payments/property/${propertyId}`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_PROPERTY_PAYMENTS_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_PROPERTY_PAYMENTS_FAIL,
                payload: error
            })
        });
};
export const getGroupPayments = ({dispatch}: { dispatch: Dispatch }, associationId: string, groupId: string) => {
    dispatch({type: GET_GROUP_PAYMENTS, payload: {}});
    authFetch(`/costs/${associationId}/payments/group/${groupId}`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_GROUP_PAYMENTS_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_GROUP_PAYMENTS_FAIL,
                payload: error
            })
        });
};


export const addPayment = ({values, dispatch, history, associationId}: { values: any, dispatch: Dispatch, history: History, associationId: string }) => {
    dispatch({
        type: ADD_PAYMENT,
        payload: {},
    });
    authFetch(`/costs/${associationId}/payments`, {
        method: "POST",
        body: JSON.stringify(values),
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: ADD_PAYMENT_SUCCESS,
                payload: data,
            });
            history.goBack();
        })
        .catch((error) => {
            dispatch({
                type: ADD_PAYMENT_FAIL,
                payload: error,
            });
        });
};

export const getPaymentById = ({dispatch, associationId, paymentId}: { dispatch: Dispatch, associationId: string, paymentId: string }) => {
    dispatch({
        type: GET_PAYMENT,
        payload: {}
    });
    authFetch(`/costs/${associationId}/payment/${paymentId}`, {
        method: "GET"
    })
        .then(result => result.json())
        .then(data => {
            dispatch({
                type: GET_PAYMENT_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_PAYMENT_FAIL,
                payload: error
            })
        })
};

export const deletePayment = ({dispatch, associationId, paymentId}: { dispatch: Dispatch, associationId: string, paymentId: string }) => {
    dispatch({
        type: DELETE_PAYMENT,
        payload: {}
    });
    authFetch(`/costs/${associationId}/payments/${paymentId}`, {
        method: 'DELETE'
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: DELETE_PAYMENT_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: DELETE_PAYMENT_FAIL,
                payload: error
            })
        })
};

export const updatePayment = ({values, dispatch, history,}: { values: any; dispatch: Dispatch; history: History; },
                              associationId: string,
                              paymentId: string) => {
    dispatch({
        type: UPDATE_PAYMENT,
        payload: {},
    });
    authFetch(`/costs/${associationId}/payments/${paymentId}`, {
        method: "PUT",
        body: JSON.stringify(values),
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: UPDATE_PAYMENT_SUCCESS,
                payload: data,
            });
            history.push(`/associations/${associationId}`);
        })
        .catch((error) => {
            dispatch({
                type: UPDATE_PAYMENT_FAIL,
                payload: error,
            });
        });
};
