import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    CHANGE_SELECTED_MONTH,
    CHANGE_SELECTED_YEAR,
    GET_COSTS_DETAILS_BY_MONTH,
    GET_COSTS_DETAILS_BY_MONTH_FAIL,
    GET_COSTS_DETAILS_BY_MONTH_SUCCESS,
    GET_PROPERTIES,
    GET_PROPERTIES_FAIL,
    GET_PROPERTIES_SUCCESS,
    GET_PROPERTY,
    GET_PROPERTY_COSTS,
    GET_PROPERTY_COSTS_FAIL,
    GET_PROPERTY_COSTS_SUCCESS,
    GET_PROPERTY_FAIL,
    GET_PROPERTY_SUCCESS,
    GET_PROPERTY_YEARS_WITH_DATA,
    GET_PROPERTY_YEARS_WITH_DATA_FAIL,
    GET_PROPERTY_YEARS_WITH_DATA_SUCCESS,
} from "./PropertiesActions";

type State = {
    properties: any[];
    loading: boolean;
    message: string;
    error: object;
    fetchedProperty: any,
    propertyRegisteredYears: [],
    selectedYear: string
    monthDetails: {
        debt: number,
        monthOfDistribution: number,
        total: number,
        totalToPay: number,
        amounts: { amount: number }[]
    } | null,
    selectedMonth: string,
    propertyExpenseTypes: [],
    expenseMonthlyAmounts: [],
    monthExpensesTypes: { name: string }[]
}

const PropertiesStateContext = createContext<State | undefined>(undefined);
const PropertiesDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    properties: [],
    loading: false,
    message: "",
    error: {},
    fetchedProperty: {},
    propertyRegisteredYears: [],
    selectedYear: '',
    monthDetails: null,
    selectedMonth: "",
    propertyExpenseTypes: [],
    expenseMonthlyAmounts: [],
    monthExpensesTypes: []
};
const propertiesReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_PROPERTIES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PROPERTIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                properties: action.payload
            }
        }
        case GET_PROPERTIES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                properties: []
            }
        }
        case GET_PROPERTY: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PROPERTY_SUCCESS: {
            return {
                ...state,
                loading: false,
                fetchedProperty: action.payload
            }
        }
        case GET_PROPERTY_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_PROPERTY_COSTS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PROPERTY_COSTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                expenseMonthlyAmounts: action.payload.months,
                propertyExpenseTypes: action.payload.columns,
            }
        }
        case GET_PROPERTY_COSTS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_PROPERTY_YEARS_WITH_DATA: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PROPERTY_YEARS_WITH_DATA_SUCCESS: {
            let selectedYear = "";
            action.payload && action.payload.forEach((year: { year: string, currentYear: boolean }) => {
                if (year.currentYear) {
                    selectedYear = year.year
                }
            });
            return {
                ...state,
                loading: false,
                propertyRegisteredYears: action.payload,
                selectedYear
            }
        }
        case GET_PROPERTY_YEARS_WITH_DATA_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case CHANGE_SELECTED_YEAR: {
            return {
                ...state,
                selectedYear: action.payload
            }
        }
        case GET_COSTS_DETAILS_BY_MONTH: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_COSTS_DETAILS_BY_MONTH_SUCCESS: {
            return {
                ...state,
                loading: false,
                monthDetails: action.payload.months && action.payload.months[0],
                monthExpensesTypes: action.payload.columns,
            }
        }
        case GET_COSTS_DETAILS_BY_MONTH_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                monthDetails: [],
                monthExpensesTypes: []
            }
        }
        case CHANGE_SELECTED_MONTH: {
            return {
                ...state,
                selectedMonth: action.payload
            }
        }
        default: {
            return state;
        }
    }
};
const PropertiesProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(propertiesReducer, initialState);
    return (
        <PropertiesStateContext.Provider value={state}>
            <PropertiesDispatchContext.Provider value={dispatch}>
                {children}
            </PropertiesDispatchContext.Provider>
        </PropertiesStateContext.Provider>
    )
};

const usePropertiesState = () => {
    const context = useContext(PropertiesStateContext);
    if (context === undefined) {
        throw new Error('usePropertiesState must be used within a PropertiesProvider')
    }
    return context
};

const usePropertiesDispatch = () => {
    const context = useContext(PropertiesDispatchContext);
    if (context === undefined) {
        throw new Error(' usePropertiesDispatch must be used within a PropertiesProvider');
    }
    return context
}

export {PropertiesProvider, usePropertiesState, usePropertiesDispatch}