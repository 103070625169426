import React from "react";
import AppRouter from "./router/AppRouter";
import {LoginProvider} from "./components/auth/AuthContext";
import {UserProvider} from "./components/users/UsersContext";
import {InvitesProvider} from "./components/invites/InvitesContext";
import {GlobalProvider} from "./config/GlobalContext"
import {PropertiesProvider} from "./components/properties/PropertiesContext";
import {PaymentsProvider} from "./components/payments/PaymentsContext";

function App() {
    return (
        <div className="App">
            <GlobalProvider>
                <LoginProvider>
                    <UserProvider>
                        <PropertiesProvider>
                            <InvitesProvider>
                                <PaymentsProvider>
                                    <AppRouter/>
                                </PaymentsProvider>
                            </InvitesProvider>
                        </PropertiesProvider>
                    </UserProvider>
                </LoginProvider>
            </GlobalProvider>
        </div>
    );
}

export default App;
